export default [{
  label: 'Razão social', field: 'razaoSocial', type: 'custom',
},
{ label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'col-nowrap' },
{
  label: 'Situação',
  field: 'situacao',
  type: 'custom',
  tdClass: 'col-nowrap',
}, // reguar ou irregular
{ label: 'Consultado em', field: 'ultimaConsulta', thClass: 'col-nowrap' },
{ label: 'Próxima consulta', field: 'proximaConsulta', thClass: 'col-nowrap' },
{ label: 'Data da validade', field: 'validade', thClass: 'col-nowrap' },
// {
//  label: 'Situação fiscal',
//  field: 'statusSituacaoFiscal',
//  type: 'custom',
//  tdClass: 'col-tamanho-situacao',
// },
]
